import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "./../index.scss"
import Blog from "../elements/Blog"

const JournalList = props => (
  <Layout>
    <SEO title="Journal" />
    <Blog data={props} />
  </Layout>
)

export const JournalListQuery = graphql`
  query($skip: Int!, $limit: Int!) {
    allContentfulJournalEntry(
      limit: $limit
      skip: $skip
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        categoryGroup {
          category
        }
        title
        url
        mainImage {
          fixed(width: 390, height: 590, cropFocus: CENTER) {
            src
          }
        }
      }
    }
  }
`

export default JournalList
