import React from 'react';
import { Link } from 'gatsby';
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

export default function Pagination(props) {

    const linkGenerator = (pages) => {
        let links = []
        for(let i = 0; i < pages; ++i){
            let pageNum = i === 0 ? '' : `/${i + 1}`
            links.push(
              <li key={i} className={`${i === props.context.pageNumber ? 'active' : ''}`}>
                <Link to={`/journal${pageNum}`}>{i + 1}</Link>
              </li>
            )
        }

        return links
    }
    return (
      <div className="rn-pagination text-center">
        <ul className="page-list">
          {props.context.previousPagePath !== "" ? (
            <li>
              <Link to={props.context.previousPagePath}>
                <FaAngleLeft />
              </Link>
            </li>
          ) : (
            ""
          )}
          {linkGenerator(props.context.numberOfPages)}
          {props.context.nextPagePath !== "" ? (
            <li>
              <Link to={props.context.nextPagePath}>
                <FaAngleRight />
              </Link>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
    )
}